import request from '@/utils/request'

// 查询告警记录列表
export function listVehicleWarn (query) {
  return request({
    url: '/iot/warn/list',
    method: 'get',
    params: query
  })
}

// 查询告警记录详细
export function getVehicleWarn (id) {
  return request({
    url: '/iot/warn/' + id,
    method: 'get'
  })
}

// 新增告警记录
export function addVehicleWarn (data) {
  return request({
    url: '/iot/warn',
    method: 'post',
    data: data
  })
}

// 修改告警记录
export function updateVehicleWarn (data) {
  return request({
    url: '/iot/warn',
    method: 'put',
    data: data
  })
}

// 删除告警记录
export function delVehicleWarn (id) {
  return request({
    url: '/iot/warn/' + id,
    method: 'delete'
  })
}

// 导出告警记录
export function exportVehicleWarn (query) {
  return request({
    url: '/iot/warn/export',
    method: 'get',
    params: query
  })
}
