<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆编号" prop="vehicleId">
        <a-input v-model="form.vehicleId" placeholder="请输入车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="告警实例id(车端)" prop="uuid">
        <a-input v-model="form.uuid" placeholder="请输入告警实例id(车端)" />
      </a-form-model-item>
      <a-form-model-item label="告警类型" prop="warnType">
        <a-select placeholder="请选择告警类型" v-model="form.warnType">
          <a-select-option v-for="(d, index) in warnTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="告警级别(车端)" prop="level">
        <a-input v-model="form.level" placeholder="请输入告警级别(车端)" />
      </a-form-model-item>
      <a-form-model-item label="告警描述(车端)" prop="warnDesc">
        <a-input v-model="form.warnDesc" placeholder="请输入告警描述(车端)" />
      </a-form-model-item>
      <a-form-model-item label="告警触发时间(车端)" prop="triggedTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.triggedTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="告警清除时间(车端)" prop="clearTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.clearTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="告警类型(车端)" prop="typeId">
        <a-input v-model="form.typeId" placeholder="请输入告警类型(车端)" />
      </a-form-model-item>
      <!-- <a-form-model-item label="告警状态 1待处理 2已处理" prop="warnStatus">
        <a-radio-group v-model="form.warnStatus" button-style="solid">
          <a-radio-button value="">请选择字典生成</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleWarn, addVehicleWarn, updateVehicleWarn } from '@/api/iot/vehicleWarn'

export default {
  name: 'CreateForm',
  props: {
    warnTypeOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        uuid: null,
        warnType: null,
        level: null,
        warnDesc: null,
        triggedTime: null,
        clearTime: null,
        typeId: null,
        warnStatus: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        uuid: null,
        warnType: null,
        level: null,
        warnDesc: null,
        triggedTime: null,
        clearTime: null,
        typeId: null,
        warnStatus: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleWarn(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleWarn(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleWarn(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
