var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆编号", prop: "vehicleId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆编号" },
                model: {
                  value: _vm.form.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleId", $$v)
                  },
                  expression: "form.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警实例id(车端)", prop: "uuid" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入告警实例id(车端)" },
                model: {
                  value: _vm.form.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "uuid", $$v)
                  },
                  expression: "form.uuid",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警类型", prop: "warnType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择告警类型" },
                  model: {
                    value: _vm.form.warnType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "warnType", $$v)
                    },
                    expression: "form.warnType",
                  },
                },
                _vm._l(_vm.warnTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警级别(车端)", prop: "level" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入告警级别(车端)" },
                model: {
                  value: _vm.form.level,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "level", $$v)
                  },
                  expression: "form.level",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警描述(车端)", prop: "warnDesc" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入告警描述(车端)" },
                model: {
                  value: _vm.form.warnDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "warnDesc", $$v)
                  },
                  expression: "form.warnDesc",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警触发时间(车端)", prop: "triggedTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.triggedTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "triggedTime", $$v)
                  },
                  expression: "form.triggedTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警清除时间(车端)", prop: "clearTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.clearTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "clearTime", $$v)
                  },
                  expression: "form.clearTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "告警类型(车端)", prop: "typeId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入告警类型(车端)" },
                model: {
                  value: _vm.form.typeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "typeId", $$v)
                  },
                  expression: "form.typeId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }